<template>
    <div class="card border-0 card-with-shadow">
        <div class="card-header bg-transparent p-primary">
            <div class="row">
                <div class="col-8">
                    <h5 v-show="isShowActive" class="card-title text-muted m-0">
                        Industrii
                    </h5>
                    <h5 v-show="!isShowActive" class="card-title text-muted m-0">
                        Editare Industrii
                    </h5>
                </div>
                <div class="col-4">

                    <div class="d-flex align-items-center justify-content-end h-100">

                        <a v-show="!isShowActive" class="text-muted" href="#"
                           @click.prevent="cancelEdits">
                            <app-icon name="x-square" stroke-width="1"/>
                        </a>

                        <a v-show="!isShowActive" class="text-muted" href="#"
                           @click.prevent="saveEdits">
                            <app-icon name="check-square" stroke-width="1"/>
                        </a>

                        <a v-show="isShowActive" class="text-muted" href="#"
                           @click.prevent="jobTitleEdit">
                            <app-icon name="edit" stroke-width="1"/>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <div class="card-body">
            <div v-show="isShowActive">

                <template v-if="formData.length">

                    <div v-for="(data, index) in formData"
                         :key="index"
                         :class="{'mb-3': data.length > 1 && index !== data.length - 1}"
                         class="d-flex justify-content-between align-items-center">

                        <div class="media d-flex align-items-center mt-2">
                            <div class="media-body">
                                <p class="text-muted font-size-90 mb-0">
                                    <span> * {{ data.name }},  CAEN: {{ data.caen_code }} </span>
                                </p>
                            </div>

                        </div>

                    </div>
                </template>

                <template v-else>
                    <p class="text-muted">Nu a fost introdusa nicio industrie</p>
                    <a class="font-size-90" href="#" @click.prevent="jobTitleEdit">
                        {{ componentType == 'organization' ? '+ Adauga cifra afaceri' : '+ Adauga companie' }}
                    </a>
                </template>

            </div>

            <div v-show="!isShowActive">

                <div v-for="(data, index) in formData"
                     :key="index"
                     :class="{'mb-3': data.length > 1 && index !== data.length - 1}"
                     class="form-group">

                    <div class="form-row align-items-center">

                        <div class="col-10">
                            <app-input v-model="data.id"
                                       :list="Object.values(dataList)"
                                       placeholder="Alege o industrie"
                                       list-value-field='name'
                                       type="search-select"/>
                        </div>

                        <div class="col-2 d-flex justify-content-center">
                            <a class="text-muted" href="#" @click.prevent="removeData(index)">
                                <app-icon name="trash" stroke-width="1" width="20"/>
                            </a>
                        </div>

                    </div>
                </div>
                <a href="#" @click.prevent="addMoreIndustries">
                    + adauga mai mult
                </a>
            </div>
        </div>
    </div>

</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin.js";
import {urlGenerator} from "@app/Helpers/helpers";

export default {

    name: "DetailsIndustry",
    mixins: [FormMixin],
    props: {

        componentType: {
            type: String,
            required: true
        },
        organizationId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            route,
            urlGenerator,
            isShowActive: true,
            formData: {},
            dataList: {},
            errors: {},
        }
    },

    mounted() {
        this.prepareData();
    },
    methods: {
        async prepareData() {
            await this.getData();
        },

        async getData() {
            try {
                this.formData = (await axios({
                    method: 'get',
                    url: route('industry.get-by-organization', {id: this.organizationId})
                })).data;
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                }
            }

            try {
                this.dataList = (await axios({
                    method: 'get',
                    url: route('industry.index'),
                    params: {all: true}
                })).data;
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                }
            }

        },

        addMoreIndustries() {
            this.formData.push({
                value: '',
                year: null
            })
        },

        jobTitleEdit() {
            this.isShowActive = false;
        },

        cancelEdits() {
            this.isShowActive = true;
            this.formData = JSON.parse(JSON.stringify(this.dataList));
        },

        saveEdits() {
            this.axiosPost({
                url: route('industry.sync'),
                data: {
                    industries: this.formData,
                    organizationId: this.organizationId
                }
            }).then(response => {
                this.afterSuccess(response);
            }).catch((error) => {
                this.errors = error.response.data.errors;
            }).finally(() => {
                this.prepareData();
            })
        },

        afterSuccess(response) {
            this.isShowActive = true
            this.errors = {};

        },
        removeData(index) {
            this.formData.splice(index,1);
        },
    },
}
</script>
