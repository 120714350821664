<template>
    <form class="mb-0" ref="form" :data-url="props.activitySyncUrl">
        <div class="mb-primary">
            <div class="form-group">
                <div class="form-row">
                    <label class="mb-0 col-sm-2 d-flex align-items-center">
                        Activitate
                    </label>
                    <div class="col-sm-10">
                        <app-input type="radio-buttons"
                                   :list="activityTypeList"
                                   v-model="activity_type_id"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-row">
                    <label class="mb-0 col-sm-2 d-flex align-items-center">
                        Titlu
                    </label>
                    <div class="col-sm-10">
                        <app-input type="text"
                                   :placeholder="'Introduce titlu'"
                                   :required="true"
                                   v-model="formData.title"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="form-row">
                    <label class="mb-0 col-sm-2 d-flex mt-2">Descriere</label>
                    <div class="col-sm-10">
                        <app-input
                            type="textarea"
                            :text-area-rows="5"
                            :placeholder="'Descriere aici'"
                            v-model="formData.description"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="form-row">
                    <label class="mb-0 col-sm-2 d-flex align-items-center">
                        Stabileste orar
                    </label>
                    <div class="col-sm-10">
                        <div class="row mb-1">
                            <div class="col-lg-7 pr-lg-0">
                                <app-input type="date"
                                           :placeholder="'Data start'"
                                           v-model="formData.started_at"
                                           @input="setEndDateAsStartDate"
                                />
                            </div>
                            <div class="col-lg-5 pl-lg-0 time-picker-dd-pos">
                                <app-input type="time" :class="(isEmailActivity || isCallActivity) && 'd-none'"
                                           v-model="startTime"
                                           @input="setStart($event)"
                                />
                            </div>
                        </div>
                        <div class="row " :class="(isEmailActivity || isCallActivity) && 'd-none'">
                            <div class="col-lg-7 pr-lg-0">
                                <app-input type="date"
                                           :class="(isMeetingActivity) && 'd-none'"
                                           :min-date="formData.ended_at"
                                           :placeholder="'Data incheiere'"
                                           v-model="formData.started_at"/>
                            </div>
                            <div class="col-lg-5 pl-lg-0 time-picker-dd-pos">
                                <app-input type="time"
                                           v-model="endTime"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Participants input -->
            <div :class="!isEmailActivity && !isCallActivity ? 'form-group' : 'd-none'">
                <div class="form-row">
                    <label class="mb-0 col-sm-2 d-flex align-items-center">Participanti</label>
                    <div class="col-sm-10">
                        <app-input
                            type="multi-select"
                            :list="personList"
                            list-value-field="name"
                            v-model="formData.person_id"
                            :is-animated-dropdown="true"
                        />
                    </div>
                </div>
            </div>
            <!-- end of Participants input -->

            <!-- Collaborators input -->
            <div :class="!isEmailActivity && !isCallActivity ? 'form-group' : 'd-none'">
                <div class="form-row">
                    <label class="mb-0 col-sm-2 d-flex align-items-center text-break">Colaboratori</label>
                    <div class="col-sm-10">
                        <app-input
                            type="multi-select"
                            :list="ownerList"
                            list-value-field="full_name"
                            v-model="formData.owner_id"
                            :is-animated-dropdown="true"
                        />
                    </div>
                </div>
            </div>
            <!-- end of Collaborators input -->
            <div class="form-group">
                <div class="form-row">
                    <label class="mb-0 col-sm-2 d-flex align-items-center">Salveaza ca finalizat</label>
                    <div class="mt-2 col-sm-10">
                        <app-input
                            type="single-checkbox"
                            :list-value-field="$t(' ')"
                            v-model="formData.activity_done"
                        />
                    </div>
                </div>
            </div>

        </div>
        <div class="pt-primary px-primary border-top mx-minus-primary">
            <button type="button" class="btn btn-primary" @click.prevent="submitData">
                        <span class="w-100">
                            <app-submit-button-loader v-if="loading"></app-submit-button-loader>
                        </span>
                <template v-if="!loading">Salveaza</template>
            </button>
        </div>
    </form>
</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin";
import {mapGetters} from 'vuex'
import {collect} from "@app/Helpers/Collection";
import {formatted_time, time_format,} from "@app/Helpers/helpers";

export default {
    name: "ActivityTab",
    props: ['props'],
    mixins: [FormMixin],
    data() {
        return {
            formatted_time,
            activityTypeList: [],
            loading: false,
            activity_type_id: null,
            doneActivityId: null,
            endTime: moment(new Date()).format(`${time_format()}`),
            startTime: moment(new Date())
                .subtract("15", "minutes")
                .format(`${time_format()}`),
            formData: {
                title: '',
                started_at: new Date(),
                ended_at: new Date(),
                person_id: [],
                owner_id: [],
            }
        }
    },
    computed: {
        ...mapGetters({
            ownerList: 'getOwner',
            organizationList: 'getOrganization',
            personList: 'getPerson',
            statusList: 'getActivityStatus'
        }),
        isEmailActivity() {
            return this.activity_type_id === 3;
        },

        isCallActivity() {
            return this.activity_type_id === 1;
        },

        isMeetingActivity() {
            return this.activity_type_id === 2;
        }
    },
    created() {

    },
    methods: {
        setStart(v) {
            this.startTime = v;
            this.formData.start_time = v;
            if (
                this.formData.started_at.toDateString() ==
                this.formData.ended_at.toDateString()
            ) {
                this.endTime =
                    this.convertTime12to24(v) > this.convertTime12to24(this.endTime)
                        ? this.startTime
                        : this.endTime;
            }
        },
        convertTime12to24(time12h) {
            if (formatted_time() == 24) {
                return time12h;
            }
            const [time, modifier] = time12h.split(" ");

            let [hours, minutes] = time.split(":");

            if (hours === "12") {
                hours = "00";
            }

            if (modifier === "PM") {
                hours = parseInt(hours, 10) + 12;
            }

            return `${hours}:${minutes}`;
        },
        setEndDateAsStartDate() {

            // please no need to change this formation of date,
            // it is just use for check logic

            let s = moment(moment(this.formData.started_at).format("YYYY-MM-DD")),
                e = moment(moment(this.formData.ended_at).format("YYYY-MM-DD")),
                diff = e.diff(s, "days");

            if (diff < 0) {
                this.formData.ended_at = this.formData.started_at;
            }
        },
        beforeSubmit() {
            this.loading = true;
        },
        submitData() {

            // let selectedStartDate = this.formData.started_at;
            // let selectedEndDate = this.formData.ended_at;

            let activity = this.formData;
            activity.activity_type_id = this.activity_type_id;
            activity.title = this.formData.title;
            activity.description = this.formData.description;
            activity.started_at = moment(this.formData.started_at).format('YYYY-MM-DD');

            activity.ended_at = (!this.isEmailActivity || !this.isCallActivity || !this.isMeetingActivity)
                ? moment(this.formData.ended_at).format('YYYY-MM-DD')
                : activity.started_at;

            activity.start_time = (!this.isEmailActivity || !this.isCallActivity)
                ? this.convertTime12to24(this.startTime)
                : "00:00 AM";

            activity.end_time = (!this.isEmailActivity || !this.isCallActivity || !this.isMeetingActivity)
                ? this.convertTime12to24(this.endTime)
                : activity.start_time;
            activity.contextable_id = this.props.id;
            activity.contextable_type = this.props.contextable_type;
            activity.person_id = this.formData.person_id;
            activity.owner_id = this.formData.owner_id;

            if (this.formData.activity_done) {
                activity.status_id = collect(this.statusList).where('name', '=', 'status_done').first().id;
            }

            this.save(activity);

            // this.formData.started_at = selectedStartDate;
            // this.formData.ended_at = selectedEndDate;

        },
        afterError(response) {
            this.loading = false;
            this.$toastr.e(response.data.message);
        },

        afterSuccess(response) {
            let todoStatusId = collect(this.statusList).where('name', '=', 'status_todo').first().id;
            let doneStatusId = collect(this.statusList).where('name', '=', 'status_done').first().id;
            let status = this.formData.activity_done ? doneStatusId : todoStatusId;
            this.input = ''
            this.formData = {
                title: '',
                started_at: new Date(),
                ended_at: new Date(),
                person_id: [],
                owner_id: [],
            };
            this.activity_type_id = this.activityTypeList.length ? collect(this.activityTypeList).first().id : null;
            this.$toastr.s(response.data.message);
            this.$hub.$emit('activity-list', `status=${status}`);
        },
        afterFinalResponse() {
            this.loading = false;
        },

     getActivityType() {

          return this.axiosGet(route('activity_types.index'))
              .then((response) => {
                this.activityTypeList = this.collection(response.data.data).shaper(
                    "name"
                );
                  this.activity_type_id = this.activityTypeList.length ? collect(this.activityTypeList).first().id : null;
              })
              .catch((error) => {
                console.log(error);
              });
     },
  },
  mounted() {
      this.$store.dispatch("getActivityStatus");
    this.getActivityType();

    }
}
</script>
