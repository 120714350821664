<template>
    <div class="content-wrapper">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <app-breadcrumb
                    :page-title="'Zona geografica'"
                    :directory="[$t('contact'), $t('contact_type')]"
                    :icon="'clipboard'"/>
            </div>
            <div class="col-sm-12 col-md-6" v-if="$can('create_types')">
                <div class="float-md-right mb-3 mb-sm-3 mb-md-0">
                    <button
                        type="button"
                        class="btn btn-primary btn-with-shadow"
                        @click.prevent="openModal">
                        Adauga Zona
                    </button>
                </div>
            </div>
        </div>

        <app-table :id="tableId" :options="options" @action="getAction"/>

        <geografical-area-modal v-if="isModalActive"
                        :table-id="tableId"
                        :selected-url="selectedUrlContact"
                        @close-modal="closeModal"/>

        <app-confirmation-modal v-if="confirmationModalActive"
                                modal-id="delete-modal"
                                @confirmed="confirmed"
                                @cancelled="cancelled"/>

    </div>
</template>


<script>
import {FormMixin} from "@core/mixins/form/FormMixin";
import {DeleteMixin} from "@app/Mixins/Global/DeleteMixin";
import GeograficalAreaModal from "./GeograficalAreaModal";

export default {
    name: "GeograficalAreaList",
    components: {GeograficalAreaModal},
    mixins: [FormMixin, DeleteMixin],
    data() {
        return {
            confirmationModalActive: false,
            isModalActive: false,
            tableId: 'geografical-zone-table',
            selectedUrlContact: '',
            options: {
                name: this.$t('pipeline_table'),
                url: route('geografical-area.index'),
                showHeader: true,
                columns: [
                    {
                        title: 'Nume',
                        type: 'text',
                        key: 'name',
                    },
                ],
                filters: [
                    {
                        "title": 'Data crearii',
                        "type": "range-picker",
                        "key": "date",
                        "option": ["today", "thisMonth", "last7Days", "thisYear"]
                    },

                ],
                showSearch: true,
                showFilter: true,
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                actionType: "default",
                enableCookie: false,
                showCount: true,
                showClearFilter: true,
                actions: [
                    {
                        title: 'Editare',
                        icon: 'edit',
                        type: 'modal',
                        component: 'geografical-area-modal',
                        modalId: 'geografical-area-modal',
                        modifier: () => this.$can("update_types"),

                    }, {
                        title: 'Stergere',
                        icon: 'trash',
                        type: 'modal',
                        component: 'app-confirmation-modal',
                        modalId: 'delete-modal',
                        url: '',
                        modifier: () => this.$can("delete_types"),
                    },
                ],
            }
        }
    },
    methods: {
        getAction(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrlContact = route('geografical-area.show', {id: row.id});
                this.isModalActive = true;
            } else if (action.title == this.$t('delete')) {

                this.deleteUrl = route('geografical-area.destroy', {id: row.id});
                this.confirmationModalActive = true;
            }
        },
        openModal() {
            this.isModalActive = true;
            $('#' + this.tableId).modal('show');
        },
        closeModal() {
            this.isModalActive = false;
            this.selectedUrlContact = '';
            $('#' + this.tableId).modal('hide');
        }
    },
    created() {
        if (this.$can("update_types") || this.$can("delete_types")) {
            this.options.columns = [...this.options.columns, {
                title: 'Actiuni',
                type: 'action',
                key: 'invoice',
                isVisible: true
            },]
        }

    }
}
</script>

<style>
.badge-link {
    color: #0f37da;
}
</style>
