<template>
	<div class="card border-0 card-with-shadow">
		<div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
			<h5 v-show="isDetailsEditable" class="card-title text-muted mb-0">Detalii adresa</h5>
			<h5 v-show="!isDetailsEditable" class="card-title text-muted mb-0">Editare detalii adresa</h5>

			<div>
				<a href="#" v-show="!isDetailsEditable" class="text-muted" @click.prevent="showDetailsClose">
					<app-icon stroke-width="1" name="x-square"/>
				</a>
				<a href="#" v-show="!isDetailsEditable" class="text-muted" @click.prevent="showDetailsSave">
					<app-icon stroke-width="1" name="check-square"/>
				</a>
				<a href="#" v-show="isDetailsEditable" class="text-muted" @click.prevent="showDetailsEditable">
					<app-icon stroke-width="1" name="edit"/>
				</a>
			</div>
		</div>

		<div class="card-body">
			<div v-show="isDetailsEditable">
        <template v-if="(addressDetails.country || addressDetails.area || addressDetails.state || addressDetails.city || addressDetails.zip_code || addressDetails.address)">

          <div class="form-group mb-3">
            <div class="form-row" v-if="addressDetails.country">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Tara</label>
              <div class="col-9">
                {{ addressDetails.country.name }}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="addressDetails.area">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Zona</label>
              <div class="col-9">
                {{ addressDetails.area }}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="addressDetails.city">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Oras</label>
              <div class="col-9">
                {{ addressDetails.city }}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="addressDetails.state">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Stat</label>
              <div class="col-9">
                {{ addressDetails.state }}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="addressDetails.zip_code">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Cod postal</label>
              <div class="col-9">
                {{ addressDetails.zip_code }}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="addressDetails.address">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Adresa</label>
              <div class="col-9">
                {{ addressDetails.address }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="text-muted">Nu este adaugat inca</p>
          <a href="#" class="font-size-90" @click.prevent="showDetailsEditable">
            + Adauga adresa
          </a>
        </template>
			</div>

			<div v-show="!isDetailsEditable">

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Tara</label>
					<div class="col-9">
						<app-input
							type="search-select"
							list-value-field="name"
							:list="countryList"
							:placeholder="'Alege tara'"
							v-model="addressDetails.country_id"
						/>
					</div>
				</div>

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Zona</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Introduce zona'"
						           v-model="addressDetails.area"/>
					</div>
				</div>


				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Oras</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Introduce oras'"
						           v-model="addressDetails.city"/>
					</div>
				</div>

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Stat</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Introduce stat'"
						           v-model="addressDetails.state"/>
					</div>
				</div>

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Cod postal</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Introduce cod postal'"
						           v-model="addressDetails.zip_code"/>
					</div>
				</div>

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Adresa</label>
					<div class="col-9">
						<app-input type="textarea"
                       :placeholder="'Adauga detaliile adresei aici'"
						           v-model="addressDetails.address"/>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
import {FormMixin} from "../../../core/mixins/form/FormMixin";

export default {
	name: "AddressDetails",
	mixins: [FormMixin],
	props: {
		addressDetails: {
			type: Object,
			required: true
		},
		addressUpdateUrl: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			isDetailsEditable: true,
			countryList: [],
      formData: {},
		}
	},

	methods: {
		showDetailsSave() {
      this.formData.name = this.addressDetails.name;
      this.formData.contact_type_id = this.addressDetails.contact_type_id;
      this.formData.owner_id = this.addressDetails.owner_id;
      this.formData.country_id = this.addressDetails.country_id;
      this.formData.area = this.addressDetails.area;
      this.formData.city = this.addressDetails.city;
      this.formData.state = this.addressDetails.state;
      this.formData.zip_code = this.addressDetails.zip_code;
      this.formData.address = this.addressDetails.address;
			this.submitFromFixin('patch', this.addressUpdateUrl, this.formData)
		},
		afterSuccess(response) {
			this.$toastr.s(response.data.message);
			this.isDetailsEditable = true
			this.$emit('update-request');
		},
		showDetailsClose() {
			this.isDetailsEditable = true;
		},
		showDetailsEditable() {
			this.isDetailsEditable = false
		},

		getAllCountrys() {
			this.axiosGet(route('countries')).then(({data}) => {
				this.countryList = data;
			});
		}
	},

	created() {
		this.getAllCountrys();
	}
}
</script>
