import {axiosGet} from "../../../Helpers/AxiosHelper";

const state = {
    industryList: [],
    all: {}
};
const getters = {
    getIndustry: state => state.industryList
};

const mutations = {
    INDUSTRY_INFO(state, data) {
        state.industryList = data.data;
        state.all = data;
    }
};

const actions = {
    getIndustry({commit}) {
        axiosGet(route('industry.index')).then((response) => {
            commit('INDUSTRY_INFO', response.data)
        }).catch((error) => console.log(error));
    }
};


export default {
    state,
    getters,
    mutations,
    actions
}
