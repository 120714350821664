import {axiosGet} from "../../../Helpers/AxiosHelper";

const state = {
    localitiesList: [],
    all: {}
};
const getters = {
    getCustomLocalities: state => state.localitiesList
};

const mutations = {
    LOCALITY_INFO(state, data) {
        state.localitiesList = data.data;
        state.all = data;
    }
};

const actions = {
    getCustomLocalities({commit}, {countyId}) {
        axiosGet(route('localities.get-by-county',{countyId:countyId})).then((response) => {
            commit('LOCALITY_INFO', response.data)
        }).catch((error) => console.log(error));
    }
};


export default {
    state,
    getters,
    mutations,
    actions
}
