<template>
    <div>
        <div v-for="(person, index) in personData" v-if="index < 2">
            <template v-if="$can('update_persons')">
                <a v-if="adminAccess(person)"
                   class="mb-1 d-flex" :href="route('persons.edit',{person: person.id})" target="_blank">
                    <span class="link-list">{{ person.name }}</span>
                    <span class="text-muted mt-1">
                    <i data-feather="external-link"></i>
                </span>
                </a>
                <a v-else
                   class="mb-1 d-flex">
                    <span class="link-list">{{ person.name }}</span>
                </a>
            </template>
            <template v-else>
                <span>{{ person.name }}</span>
            </template>

            <small v-if="personData.length > 2 && index == 1"><b>+{{ personData.length - 2 }} more</b></small>
        </div>
    </div>
</template>

<script>
import {collection} from "../../../../Helpers/helpers";

export default {
    name: "PersonColumn",
    props: {
        rowData: {
            type: Object,
            required: true
        },
        value: {
            type: Array,
        }
    },
    data() {
        return {
            route,
        }
    },
    methods:{
        adminAccess(person){
            return (window.user.roles[0].is_admin || window.user.roles[0].name == 'Manager')  || window.user.id == person.owner_id;
        },
    },
    computed: {
        personData() {
            let personsList = this.value.length ? collection(this.value).get("name", "id", "owner_id") : [];
            return personsList;
        }
    }
}
</script>
