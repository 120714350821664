<template>
    <div class="card border-0 card-with-shadow">
        <div class="card-header bg-transparent p-primary">
            <div class="row">
                <div class="col-8">
                    <h5 v-show="isShowActive" class="card-title text-muted m-0">
                        Cifra de afaceri
                    </h5>
                    <h5 v-show="!isShowActive" class="card-title text-muted m-0">
                        Editare cifra de afaceri
                    </h5>
                </div>
                <div class="col-4">


                    <div class="d-flex align-items-center justify-content-end h-100">

                        <a v-show="!isShowActive" class="text-muted" href="#"
                           @click.prevent="cancelEdits">
                            <app-icon name="x-square" stroke-width="1"/>
                        </a>

                        <a v-show="!isShowActive" class="text-muted" href="#"
                           @click.prevent="saveEdits">
                            <app-icon name="check-square" stroke-width="1"/>
                        </a>

                        <a v-show="isShowActive" class="text-muted" href="#"
                           @click.prevent="jobTitleEdit">
                            <app-icon name="edit" stroke-width="1"/>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <div class="card-body">
            <app-overlay-loader v-if="lineChartLoad"/>
            <template v-else>
                <app-chart
                    class="mb-primary"
                    type="custom-line-chart"
                    :height="230"
                    :labels="lineChartLabels"
                    :data-sets="lineChartData"
                />
            </template>

            <div v-show="isShowActive">

                <template v-if="dataList.length">

                    <div v-for="(data, index) in dataList"
                         :key="index"
                         :class="{'mb-3': data.length > 1 && index !== data.length - 1}"
                         class="d-flex justify-content-between align-items-center">

                        <div class="media d-flex align-items-center mt-2">
                            <div class="media-body">
                                <p class="text-muted font-size-90 mb-0">
                                    <span> {{ data.value }} EUR - {{ data.year }}</span>
                                </p>
                            </div>

                        </div>

                    </div>
                </template>

                <template v-else>
                    <p class="text-muted">Nu a fost introdusa nicio cifra de afaceri</p>
                    <a class="font-size-90" href="#" @click.prevent="jobTitleEdit">
                        {{ componentType == 'organization' ? '+ Adauga cifra afaceri' : '+ Adauga companie' }}
                    </a>
                </template>

            </div>

            <div v-show="!isShowActive">

                <div v-for="(data, index) in formData"
                     :key="index"
                     :class="{'mb-3': data.length > 1 && index !== data.length - 1}"
                     class="form-group">

                    <div class="form-row align-items-center">

                        <div class="col-12 mb-1">

                            <app-input v-model="data.value"
                                       placeholder="Introdu valoarea in EUR"
                                       type="text"/>

                            <!--                            <small v-if="errors[index + '.person_id']" class="text-danger">-->
                            <!--                                {{ errors[index + '.person_id'][0] }}-->
                            <!--                            </small>-->
                        </div>


                        <div class="col-10">

                            <app-input v-model="data.year"
                                       :list="Object.values(yearsDataList)"
                                       placeholder="Alege un an"
                                       list-value-field='value'
                                       type="search-select"/>
                        </div>

                        <div class="col-2 d-flex justify-content-center">
                            <a class="text-muted" href="#" @click.prevent="removeData(index)">
                                <app-icon name="trash" stroke-width="1" width="20"/>
                            </a>
                        </div>

                    </div>
                </div>
                <a href="#" @click.prevent="addMoreJobTitle">
                    + adauga mai mult
                </a>
            </div>
        </div>
    </div>

</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin.js";
import {urlGenerator} from "@app/Helpers/helpers";
import CustomLineChart from "../../../../core/components/charts/CustomLineChart";

export default {

    name: "DetailsJobTitle",
    components: {CustomLineChart},
    mixins: [FormMixin],
    props: {
        jobTitleData: {
            required: true
        },
        jobTitleUrlSync: {
            type: String,
            required: true
        },
        componentType: {
            type: String,
            required: true
        },
        organizationId: {
            type: Number,
            default: null
        },
        hideOrganizationSelect: {
            type: Boolean,
            default: false
        },
        startYear: {
            type: Number,
            default: 1970
        }
    },
    data() {
        return {
            route,
            urlGenerator,
            isShowActive: true,
            formData: {},
            dataList: {},
            errors: {},
            isPersonModalActive: false,
            lineChartLoad: true,
            lineChartData: [
                {
                    title: "Open",
                    fill: false,
                    borderWidth: 1.5,
                    borderColor: "#4466F2",
                    backgroundColor: "#4466F2",
                    data: [1111, 1231]
                }
            ],
            lineChartLabels: [2019, 2020],
        }
    },
    computed: {
        allYears: function () {
            let currentYear = new Date().getFullYear(), years = {};
            while (this.startYear <= currentYear) {
                years[--currentYear] = {
                    value: currentYear,
                    id: currentYear,
                    disabled: false,
                }
            }

            return years;
        },
        yearsDataList: function () {
            let years = this.allYears;
            this.dataList.forEach((data) => {
                if (data.year != undefined && data.year != null) {
                    years[data.year].disabled = true;
                }
            })
            return years;
        },
    },
    mounted() {
        this.prepareData();
    },
    methods: {
        prepareChartData() {
            this.lineChartData[0].data = this.dataList.map(data => data.value);
            this.lineChartLabels = this.dataList.map(data => data.year);
            this.lineChartLoad = false;
        },
        async prepareData() {
            this.getData().then(() => {
                this.prepareChartData();
            })
        },

        async getData() {
            try {
                this.dataList = (await axios({
                    method: 'get',
                    url: route('turnover.index')
                })).data;
                this.formData = JSON.parse(JSON.stringify(this.dataList));
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                }
            }
        },
        // adminAccess(personOrg) {
        //     return (window.user.roles[0].is_admin || window.user.roles[0].name == 'Manager') || window.user.id == personOrg.owner_id;
        // },
        addMoreJobTitle() {
            this.formData.push({
                value: '',
                year: null
            })

        },
        removeData(index) {
            axios({
                url: route('turnover.destroy', {id: this.formData[index].id}),
                method: 'delete'
            }).then(response => {
                this.dataList.splice(index, 1);
                this.formData = JSON.parse(JSON.stringify(this.dataList));
            }).catch((error) => {
                this.errors = error.response.data.errors;
            })
        },
        jobTitleEdit() {
            this.isShowActive = false;
        },
        cancelEdits() {
            this.isShowActive = true;
            this.formData = JSON.parse(JSON.stringify(this.dataList));

        },

        saveEdits() {
            this.lineChartLoad = true;

            this.axiosPost({
                url: route('turnover.sync'),
                data: {
                    turnovers: this.formData,
                    organizationId: this.organizationId
                }
            }).then(response => {
                this.dataList = JSON.parse(JSON.stringify(this.formData));
                this.afterSuccess(response);
            }).catch((error) => {
                this.errors = error.response.data.errors;
            }).finally(() => {
                this.prepareData();
            })
        },

        afterSuccess(response) {
            this.isShowActive = true
            this.errors = {};
            // this.$toastr.s(response.data.message);
            // this.$emit('update-request')
        }
    },
}
</script>
