import {axiosGet} from "../../../Helpers/AxiosHelper";

const state = {
    countiesList: [],
    all: {}
};
const getters = {
    getCounties: state => state.countiesList
};

const mutations = {
    COUNTY_INFO(state, data) {
        state.countiesList = data.data;
        state.all = data;
    }
};

const actions = {
    getCounties({commit}) {
        axiosGet(route('counties.index')).then((response) => {
            commit('COUNTY_INFO', response.data)
        }).catch((error) => console.log(error));
    }
};


export default {
    state,
    getters,
    mutations,
    actions
}
